import './style.scss'
import PropTypes from 'prop-types';
import { normalizeCurrency } from 'lib/utils/helpers';
import { t } from 'i18next';
import { useInjection } from 'brandi-react';
import { PayBlockModelStoreToken } from 'components/common/PayBlock/models/PayBlockModel/index.model';
import { categorizationNumber } from "../../../../lib/utils/helpers/categorizationNumber"


const getPrice = (v) => {
    if (v === undefined) {
        return '';
    }

    return v;
}

const SubscriptionToPayment = ({oldPrice, price, entity, currencies}) => {

    const payBlockModel = useInjection(PayBlockModelStoreToken);
    const successApply = payBlockModel.isCertSuccessApply() || payBlockModel.isPromoSuccessApply();
    const currency = normalizeCurrency(entity?.price_currency, currencies) || '';

    return (
        <div className="price-info form-price">
            <div className="price-left-col">
                <div className="price-label card-label">{t('subscriptions.price')}</div>
                <div className="price-row">
                    <div className="price mr-2">
                        {`${getPrice(categorizationNumber(price))} ${currency}`}
                    </div>
                    {oldPrice > price && successApply && <div className="old-price">{getPrice(oldPrice)} {currency}</div>}
                </div>
            </div>
        </div>
    )
}


SubscriptionToPayment.propTypes = {
    order: PropTypes.object,
    entity: PropTypes.object,
    currencies: PropTypes.array,
    certificateApplied: PropTypes.oneOfType([
        PropTypes.instanceOf(null),
        PropTypes.object,
    ]),
}
export default SubscriptionToPayment